import moment from 'moment'
import { randomNumber } from '@/main.js'


//let TwelveMonthsChart = [
//    startMonth, randomNumber(11,100)
//]

export default function TwelveMonthsChart(numberOfSeries = 1, withCats = false) {
  
    let series =  [];
    let cats = []
    for (let serieN = 0;  serieN < numberOfSeries; serieN++){
        let startMonth = moment().subtract(12,'months')
            .startOf('month')
            .valueOf();
        let serie = []
        if (withCats) {
            for (let step = 0; step < 12; step++) {
                if(serieN === 0){
                    let monthName = moment(startMonth).format('MMM')
                    cats.push(monthName)
                }
                serie.push(randomNumber(20,190))
                startMonth = moment(startMonth).add(1,'months').valueOf()
            }
        }
        else {
            for (let step = 0; step < 12; step++) {
                serie.push([startMonth,randomNumber(0, 1000) / 10])
                startMonth = moment(startMonth).add(1,'months').valueOf()
            }
        }
        series.push(serie)
    }
    return {
        series:series,
        cats:cats
    }
}