<template>

    <div class="w-full h-full module flex py-0">

        <div class="w-4/12 p-4 flex flex-col">

            <div class="flex items-center mb-2 h-12">
                <div class="module-title self-center mr-auto">
                    SL
                </div>
                <div class="text-font-gray mr-2">
                    Sort By
                </div>
                <div class="w-auto">
                    <multiselect v-model="sortBy" :options="optionsFilter" label="name" />
                </div>
            </div>

            <div class="h-full overflow-y-auto relative">

                <loader :loading="loadingsl"></loader>

                <div class="scroll-gradient"></div>

                <transition-group v-if="slElements" name="fade" tag="div" class="h-full overflow-y-auto">

                    <div class="box mb-2" v-for="(el,index) in slElementsOrdered" :key="'_'+index">

                        <div class="flex h-8">
                            <div class="h-6 mt-1 w-1 mr-3 rounded"  :style="'background-color:' + legendColors[el.id].color"></div>
                            <div class="text-2xl">
                                {{el.name}}
                            </div>
                            <div class="h-8 w-8 rounded-lg cursor-pointer ml-auto flex flex-col justify-center items-center" :class="includesInSLSelected(el.id)" @click="toggleSlSelectedItems(el.id)">
                                <i class="mdi mdi-ray-start-arrow"></i>
                            </div>
                        </div>

                        <div class="h-34 flex">
                            <div class="w-2/5 flex flex-col justify-around">
                                <div class="flex items-center">
                                    <div class="h-2 w-2 rounded-full shadow mr-2" style="background-color: #8CEABB;"></div>
                                    <div class="text-font-gray">Current Chargeability</div>
                                    <div class="text-2xl ml-auto font-bold" >{{el.ytd | numberFormat(0)}}%</div>
                                </div>
                                <div class="flex items-center">
                                    <div class="h-2 w-2 rounded-full shadow mr-2" style="background-color: #D38DDE;"></div>
                                    <div class="text-font-gray">Y-1 Chargeability</div>
                                    <div class="text-2xl ml-auto font-bold">{{el.ytd_1 | numberFormat(0)}}%</div>
                                </div>
                            </div>
                            <div class="w-3/5 pl-4">
                                <div class="h-full">
                                    <chart-col-compare-small
                                    chartId="salesCompare"
                                    :data="[el.mtd, el.mtd_1]"
                                    :categories="TwelveMonthsChartCompare.cats"
                                    :names="['Current Chargeability','Y-1 Chargeability']"
                                    :marginchart="true"/>
                                </div>
                            </div>
                        </div>

                    </div>

                </transition-group>

            </div>

        </div>

        <separator :direction="'vertical'" />

        <div class="w-4/12 p-4 flex flex-col">

            <div class="h-12 w-full flex flex-row items-center mb-2 flex flex-row justify-center items-center">

                <div class="module-title self-center flex-none">
                    SMU
                </div>

                <div class="flex flex-1 min-w-0 ml-auto overflow-x-auto pl-2">

                    <swiper ref="homeMenu" :options="swiperOptions" class="h-12 w-full">

                        <swiper-slide class="h-full">

                            <div @click="toggleSlSelectedItemsAll()" class="pill-gray h-full flex flex-col justify-center items-center">
                                <span :class="{'text-aux':all}">ALL</span>
                            </div>

                        </swiper-slide>

                        <swiper-slide v-for="(pill, k) in legendColors" :key="k" class="h-full">

                            <div @click="toggleSlSelectedItems(k)" class="pill-gray h-full flex flex-col justify-center items-center"
                            :style="getOpacity(pill, k)">
                                {{ pill.name }}
                            </div>

                        </swiper-slide>

                    </swiper>

                </div>

            </div>

            <div class="h-full overflow-auto relative">

                <loader :loading="loadingsmu"></loader>

                <div v-if="!loadingsmu" class="h-full">

                    <div class="scroll-gradient"></div>

                    <transition-group v-if="smuElements && smulElementsOrdered.length > 0" name="fade" tag="div" class="h-full overflow-y-auto">

                        <div class="box mb-2 flex h-40" v-for="(el,index) in smulElementsOrdered" :key="index+'-'">

                            <div class="w-1 rounded mr-2 flex-none" :style="'background-color:' + legendColors[el.id_sl].color"></div>

                            <div class="w-1/3 overflow-hidden flex flex-col">
                                <div class="text-xl mb-4">{{el.name}}</div>
                                <div class="flex-1 min-h-0 flex flex-col justify-center items-start">

                                    <div class="flex flex-row justify-start items-center mb-6">

                                        <div class="h-2 w-2 rounded-full shadow mr-2" style="background-color: #8CEABB;"></div>
                                        <span class="text-font-gray">Current Charg.</span>
                                        <span class="text-lg ml-2 font-semibold">{{el.ytd | numberFormat(0)}}%</span>
                                    
                                    </div>

                                    <div class="flex flex-row justify-start items-center">

                                        <div class="h-2 w-2 rounded-full shadow mr-2" style="background-color: #D38DDE;"></div>
                                        <span class="text-font-gray ml-auto">Y-1 Charg.</span>
                                        <span class="text-lg ml-2 font-semibold">{{ el.ytd_1 | numberFormat(0)}}%</span>

                                    </div>

                                </div>
                            </div>

                            <div class="w-2/3 flex-none pt-2">
                                <chart-col-compare-small
                                    chartId="salesCompare"
                                    :data="[el.mtd, el.mtd_1]"
                                    :categories="TwelveMonthsChartCompare.cats"
                                    :names="['Current Chargeability','Y-1 Chargeability']"
                                    :small="false"
                                    :marginchart="true"/>
                            </div>

                        </div>

                    </transition-group>


                    <div v-else class="h-full flex flex-col justify-center items-center">

                        <span class="text-font-gray">No SL Selected</span>

                    </div>

                </div>

            </div>

        </div>

        <separator :direction="'vertical'" />

        <div class="w-4/12 p-4 flex flex-col">

            <div class="h-12 flex flex-row justify-start items-center mb-2 flex-none">

                <div class="h-12 w-4/12 flex flex-row justify-start items-center module-title">
                    Categories
                </div>

                <div class="h-12 w-8/12 flex flex-row justify-start items-center">

                    <div class="h-12 mr-2 w-auto text-font-gray ml-auto flex flex-col justify-center items-center">
                        SMU
                    </div>

                    <div class="flex-1 h-12 flex flex-col justify-center items-center">
                        <multiselect v-model="sortBySmu" :options="smulElementsOrdered" label="full_name" :searchable="true"></multiselect>
                    </div>

                </div>

            </div>

            <div class="h-full overflow-auto relative">

                <loader :loading="loadingcategories"></loader>

                <div class="scroll-gradient"></div>

                <transition-group v-if="categories" name="fade" tag="div" class="h-full overflow-y-auto">

                    <div class="box h-40 mb-4 flex flex-col" v-for="(el,index) in filteredCategories" :key="'--'+index">

                        <div class="h-auto text-xl mb-4">
                            {{el.name}}
                        </div>

                        <div class="h-auto flex items-center">
                            <div class="h-2 w-2 rounded-full shadow mr-2" style="background-color: #8CEABB;"></div>
                            <div class="text-font-gray text-sm">Current Charg.</div>
                            <div class="text-xl font-semibold ml-2">{{el.ytd || 0 | numberFormat(2)}}%</div>
                            <div class="h-2 w-2 rounded-full shadow ml-auto mr-2" style="background-color: #D38DDE;"></div>
                            <div class="text-font-gray text-sm">Y-1 Charg.</div>
                            <div class="text-xl font-semibold ml-2">{{el.ytd_1 | numberFormat(2)}}%</div>
                        </div>

                        <div class="flex-1 min-h-0">
                            <chart-area chartId="nerChart" :data="[el.mtd, el.mtd_1]" :names="['Current Chargeability', 'Y-1 Chargeability']"></chart-area>
                        </div>

                    </div>

                </transition-group>

            </div>

        </div>

    </div>

</template>

<script>
    import { TwelveMonthsChart, RevenueDays } from '@/utils'
    import { state, actions } from '@/store';

    export default {
        data(){
            return {
                sortBy:{name:'Name', value: 'name', order: 'asc'},
                slElements:false,
                smuElements:false,
                pills:[
                    'ass','adv','pas','tas'
                ],
                categories:false,
                optionsFilter:[
                    {
                        name:'Name',
                        value: 'name',
                        order: 'asc'
                    },
                    {
                        name:'Current Chargeability',
                        value: 'ytd',
                        order: 'desc'
                    },
                    {
                        name:'Y-1 Chargeability',
                        value: 'ytd_1',
                        order: 'desc'
                    }
                ],
                loadingsl:false,
                loadingsmu:false,
                loadingcategories:false,
                sortBySmu:false,
                smus:[],
                swiperOptions: {
                    slidesPerView: 5.5,
                    spaceBetween: 4,
                },
                openfilters:false,
                smufiltereds:[],
                all:false
            }
        },
        computed: {
            user() {
                return state.user;
            },
            salesVsLimit(){
                return this.salesPercent - this.limit
            },
            TwelveMonthsChart(){
                return TwelveMonthsChart()
            },
            RevenueDays(){
                return RevenueDays()
            },
            TwelveMonthsChartCompare(){
                return TwelveMonthsChart(2,false)
            },
            slElementsOrdered(){

                return this._.orderBy(this.slElements, [this.sortBy.value], [this.sortBy.order])

            },
            smulElementsOrdered(){

                let selecteds = []
                let smuFiltereds = []

                for (let index = 0; index < this.smuElements.length; index++) {

                    if(this.slSelectedItems.includes(this.smuElements[index].id_sl)){

                        selecteds.push(this.smuElements[index])
                        smuFiltereds.push(this.smuElements[index].id)

                    }

                }

                this.smufiltereds = smuFiltereds

                return this._.orderBy(selecteds, [this.sortBy.value], [this.sortBy.order])

            },
            filteredCategories(){
                return this._.orderBy(this.categories, [this.sortBySmu])
            },
            params(){
                return state.globalParams
            },
            legendColors(){
                return state.slLegendColors
            },
            slSelectedItems(){
                return state.slSelectedItems
            }
        },
        methods:{
            getChart(){
                let chart =  TwelveMonthsChart(2,false)
                return chart
            },
            loadsl(){

                this.loadingsl = true
                this.axios.get('chargeability/slytd', { params: this.params }).then(response => {
                    this.loadingsl = false
                    this.slElements = response.data.data
                    this.$setColorsBySector(this.slElements)

                    state.slSelectedItems = []

                })

            },
            loadsmu(){

                this.loadingsmu = true
                this.axios.get('chargeability/competencyytd', { params: this.params }).then(response => {
                    this.loadingsmu = false
                    this.smuElements = response.data.data

                    this.smus = []

                    for (let index = 0; index < Object.values(this.smuElements).length; index++) {

                        this.smus.push(Object.values(this.smuElements)[index].name)

                    }

                })

            },
            loadcategories(){

                let paramCategories = {}

                if(this.slSelectedItems.length > 0){
                    
                    if(!this.sortBySmu){
                        paramCategories = {
                            ...this.params,
                            sl_zeus:this.slSelectedItems
                        }
                    } else {
                        
                        let param = this.sortBySmu.id.split('_')

                        paramCategories = {
                            ...this.params,
                            sl_zeus: [param[0]],
                            competencyIdWithRegionId:[param[1]]
                        }
                    }

                } else {
                    
                    paramCategories = {
                        ...this.params,
                        sl_zeus:null
                    }

                }

                this.loadingcategories = true;
                
                this.axios.get('chargeability/rankytd', { params: paramCategories }).then(response => {
                    this.categories = response.data.data
                    this.loadingcategories = false
                })

            },
            preload(){
                this.loadingcategories = true
            },
            load(){
                this.loadsl();
                this.loadsmu();
                // this.loadcategories();
            },
            getOpacity(pill, k) {
                if (this.slSelectedItems.includes(k)) {
                    return 'color:' + pill.color + ';background-color:' + pill.color + '25;'
                }
                //return this.slSelectedItems.includes(k) ?  '25' : '12'
            },
            toggleSlSelectedItems(item) {
                this.all = false;
                actions.toggleSlSelectedItems(item);
            },
            removeCharacters(str) {
                return str.replace(/[^A-Z0-9]+/ig, "_")
            },
            includesInSLSelected(sl){

                let test = false

                for (let index = 0; index < this.slSelectedItems.length; index++) {

                    if(this.slSelectedItems[index] == sl){
                        test = true
                    }

                }

                if(test == true){
                    return 'bg-aux-15 text-aux'
                } else {
                    return 'bg-filters-80 text-font-gray'
                }

            },
            categoriesSMu(list){

                let response = []
                let test = false

                for (let index = 0; index < list.length; index++) {

                    response.push(list[index].id)

                    if(index == list.length){
                        test = true
                    }

                }

                if(test){

                    return response

                }

            },
            toggleSlSelectedItemsAll(){

                this.all = !this.all

                if(state.slSelectedItems.length == 9 || !this.all){

                    actions.clearSlSelectedItems();

                } else {

                    for (let index = 0; index < Object.keys(this.legendColors).length; index++) {
                        actions.addSlSelectedItems(Object.keys(this.legendColors)[index])
                    }

                }


            }

        },
        watch:{
            params(){
                this.load();
            },
            slSelectedItems(){
                this.sortBySmu = false;
                this.loadcategories();
            },
            sortBySmu(){
                this.loadcategories();
            }
        },
        mounted(){
            this.preload();
            this.load();
        }
    }
</script>