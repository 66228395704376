import { randomNumber } from '@/main.js'

export default function RevenueDays() {
    let revenue = []
    let periods = ['<30','30< & <60','60< & <90','90<']
    let total = 0
    for (let period in periods) {
        let number = randomNumber(100, 9000 * (period + 1))
        total += number
        revenue.push({
            period: periods[period],
            value: number,
            perc: 0
        })
    }
    for (let period in revenue) {
        revenue[period].perc = revenue[period].value * 100 / total + randomNumber(0, 30)
    } 
    return revenue
}